import React, { Component } from 'react';
import Funciones from '../Resources/funciones.svg';
import Card from '../Card/Card';
import './FuncionesDistendidas.css';
import fav from '../Resources/logo-salidas.png';
import disney from '../Resources/logo-disney.png';
import { Link } from 'react-router-dom';
import conoce from './conoce-mas.pdf';

export class FuncionesDistendidas extends Component {
    constructor(props) {
        super(props);
    }


    downloadFile = (event) => {
        event.preventDefault(); 
        const element = document.createElement("a");
        element.href = conoce;
        element.download = "Conocé más.pdf";
        element.click();
    };

    render() {
        return (
            <main class="container-fluid text-center mb-5">
                <div class="row d-flex align-items-center justify-content-center">
                    <Link to="/">
                        <img src={fav} width={200}></img>
                    </Link>
                </div>
                <div class="row mt-5">
                    <div class="col-md-12">
                        <h3 class="title">Funciones Distendidas</h3>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-12">
                        <div class="container-funciones d-flex align-items-center justify-content-center bg-container-funciones p-5">
                            <Card svg={Funciones} title={"¿Qué son?"} width={"100"} height={"100"} maxWidth="700" animation={false} pagination={false}>
                                <div class="card-body-funciones">
                                    <p>Son funciones que ofrecen un entorno y experiencia con estímulos sensoriales reducidos y adaptaciones cognitivas, para que cada vez más personas neurodivergentes o que prefieran este tipo de propuestas puedan disfrutar de ir al cine o al teatro junto a sus familias y/o acompañantes. Son experiencias de entretenimiento que todos podemos implementar.</p>
                                    <p>Queremos invitarte a ser parte de esta gran iniciativa sumando tu establecimiento a la lista de cines y teatros que ofrecen funciones distendidas en América Latina. Para ayudarte, te brindamos recursos útiles y descargables acerca de cómo llevar a cabo estas funciones y cuáles son las consideraciones a tener en cuenta para que cumplan su objetivo.</p>
                                    <div className="d-flex flex-row flex-wrap justify-content-center" >
                                        <Link to="/pasouno" class="btn btn-primary col-md-8 col-6 mx-1" style={{ minWidth: "260px" }}>Armá tu función distendida</Link>
                                        <br />
                                        <Link to="" onClick={this.downloadFile} class="btn btn-outline-primary col-md-8 col-6" style={{ minWidth: "260px" }}>Conocé más</Link>
                                    </div>
                                    <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                                        <p class="font-weight-bold">Esta iniciativa cuenta con el apoyo de The Walt Disney Company Latin América y su equipo de Diversidad, Equidad e Inclusión.</p>
                                        <img src={disney} width={108} alt="" />
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
