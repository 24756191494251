import React, { Component} from 'react';
import './stepThree.css';
import logo from '../Resources/3ro.svg';
import cartel from '../Resources/cartel.png';
import Card from '../Card/Card';
import Pasos from '../Pasos/Pasos';
import SenializacionSectores from '../SenializacionSectores/SenializacionSectores';
import SenializacionAcciones from '../SenializacionAcciones/SenializacionAcciones';
import SenializacionSala from '../SenializacionSala/SenializacionSala';
import ScrollToTop from '../ScrollTop/ScrollTop';
import { Link } from 'react-router-dom';

export class StepThree extends Component {
    constructor(props) {
        super(props);

        this.loadInfo(this.info);

        this.handleToggleClickBtn1 = this.handleToggleClickBtn1.bind(this);
        this.handleToggleClickBtn2 = this.handleToggleClickBtn2.bind(this);
        this.handleToggleClickBtn3 = this.handleToggleClickBtn3.bind(this);

        this.state = {
            section: this.info[3].source,
            sectionActive: 3
        };
    }
    info = [
        {
            title: 'Señalización de sectores',
            source: []
        },
        {
            title: 'Señalización de secuencia de acciones',
            source: []
        },
        {
            title: 'Señalización dentro de las salas',
            source: []
        },
        {
            title: 'Default',
            source: []
        }
    ];
    steps = [
        { number: 1, url: '/pasouno' },
        { number: 2, url: '/pasodos' },
        { number: 3, url: '/pasotres' },
        { number: 4, url: '/pasocuatro' },
        { number: 5, url: '/gracias' }
    ];

    loadInfo = (info) => {
        info[0].source.push(<SenializacionSectores />);
        info[1].source.push(<SenializacionAcciones />);
        info[2].source.push(<SenializacionSala />);
        info[3].source.push("");
    };

    handleToggleClickBtn1() {
        this.setState(state => ({
            section: this.info[0].source,
            sectionActive: 0
        }));
    }

    handleToggleClickBtn2() {
        this.setState(state => ({
            section: this.info[1].source,
            sectionActive: 1
        }));
    }

    handleToggleClickBtn3() {
        this.setState(state => ({
            section: this.info[2].source,
            sectionActive: 2
        }));
    }

    render() {
        return (
            <main class="container-fluid text-center container-pasos">
                <ScrollToTop />
                <Pasos>
                    <Card svg={logo} title={"Adaptaciones Cognitivas"} width={"100"} height={"100"} maxWidth="1400" animation={false} pagination={true} current={this.steps[2]} pasos={this.steps}>
                        <div class="card-body-funciones card-body-funciones-pasos">
                            <div className='row d-flex align-items-center justify-content-center'>
                                <div className='col-md-12'>
                                    <p>Consiste en crear y/o adaptar entornos para que sean fáciles de comprender por todas las personas, favoreciendo así su autonomía e independencia individual.<br />Esto se logra a través de señalización adaptada con pictogramas.</p>
                                    <img src={cartel} alt="" width={150} />
                                    <p>Un pictograma es un representación clara y esquemática de un objeto, de un lugar, de una ﬁgura, de un concepto y/o de un mensaje.</p>
                                </div>
                            </div>
                            <div className='row d-flex align-items-center justify-content-center'>
                                <div className='col-12 col-xl-4'>
                                    <Link to="/pasotres" onClick={this.handleToggleClickBtn1} className={'btn  btn-card m-md-3 m-xs-1 ' + (this.state.sectionActive === 0 ? 'btn-primary' : 'btn-outline-primary')} style={{ color: this.state.sectionActive === 0 ? 'white' : '#007bff' }}
                                        onMouseOver={e => e.target.style.color = this.state.sectionActive === 0 ? '#007bff' : 'white'} onMouseOut={e => e.target.style.color = this.state.sectionActive === 0 ? 'white' : '#007bff'}>Señalización de sectores</Link>
                                </div>
                                <div className="col-12 col-xl-4">
                                    <Link to="/pasotres" onClick={this.handleToggleClickBtn2} className={'btn  btn-card m-md-3 m-xs-1 ' + (this.state.sectionActive === 1 ? 'btn-primary' : 'btn-outline-primary')} style={{ color: this.state.sectionActive === 1 ? 'white' : '#007bff' }}
                                        onMouseOver={e => e.target.style.color = this.state.sectionActive === 1 ? '#007bff' : 'white'} onMouseOut={e => e.target.style.color = this.state.sectionActive === 1 ? 'white' : '#007bff'}>Señalización de secuencia de acciones</Link>
                                </div>
                                <div className="col-12 col-xl-4">
                                    <Link to="/pasotres" onClick={this.handleToggleClickBtn3} className={'btn  btn-card m-md-3 m-xs-1 ' + (this.state.sectionActive === 2 ? 'btn-primary' : 'btn-outline-primary')} style={{ color: this.state.sectionActive === 2 ? 'white' : '#007bff' }}
                                        onMouseOver={e => e.target.style.color = this.state.sectionActive === 2 ? '#007bff' : 'white'} onMouseOut={e => e.target.style.color = this.state.sectionActive === 2 ? 'white' : '#007bff'}>Señalización dentro de las salas</Link>
                                </div>
                            </div>
                            {this.state.section}
                        </div>
                    </Card>
                </Pasos>
            </main>
        )
    }

}